import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {Button, Col, Input, message, Modal, Row, Space, Spin, Typography} from 'antd';
import type Types from 'MyTypes';
import {getById, update, terminate, approve, cancel} from './actions';
import {log} from 'app/services/logger-service';
import {equalNum, formatDateFunction, getCurrencyString, getPermissionSites, parseNum, toTitleCase} from 'app/helpers';
import {useHistory, useParams} from 'react-router';
import {
	type ContractorForeman,
	ModuleName,
	ModulePermission,
	WorkOrderStatus,
	type WorkOrderDebit,
	OrderType,
	QueryParentType,
	type Site,
	CommentParentType,
} from 'app/models';
import {uiPaths} from 'app/constants';
import {ViewWorkOrderWorkOrderBill} from './ViewWorkOrderWorkOrderBill';
import {ColBlock} from '../Common/ColBlock';
import {ColFileBlock} from '../Common/ColFileBlock';
import {Link} from 'react-router-dom';
import {ViewChildQuery} from 'app/components/Query/ViewChildQuery';
import {EditInputNumberForm} from '../Common/EditInputNumberForm';
import {CommentBlock} from '../Common/CommentBlock';

type ParamType = {
	orderId?: string;
};

// eslint-disable-next-line complexity
export const ViewWorkOrderDetail: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<ParamType>();

	const [cancelReason, setCancelReason] = React.useState<string>('');
	const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
	const [approveComment, setApproveComment] = React.useState<string>('');
	const [showApproveModal, setShowApproveModal] = React.useState<boolean>(false);

	const {byIds: workOrderByIds, loading, dataUpdated, errorMessage} = useSelector((state: Types.RootState) => state.workOrder);
	const {byIds: scheduleByIds} = useSelector((state: Types.RootState) => state.workOrderSchedule);
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);
	const {byModule: permissions, allSites} = useSelector((state: Types.RootState) => state.userPermission);

	const orderId: number = parseInt(params?.orderId ?? '0', 10);

	let approveReqMade = false;
	const filter: any = {
		where: {},
		offset: 0,
		limit: 100,
		skip: 0,
		order: [
			'createdAt DESC',
		],
		include: [
			{relation: 'contractorForemen'},
			{relation: 'paymentMilestones'},
			{relation: 'debits'},
		],
	};

	const writeSites: Site[] = getPermissionSites(permissions, ModuleName.WORK_ORDER, ModulePermission.WRITE, allSites);
	const approveSites: Site[] = getPermissionSites(permissions, ModuleName.WORK_ORDER, ModulePermission.APPROVE, allSites);
	const viewBudgetSites: Site[] = getPermissionSites(permissions, ModuleName.WORK_ORDER_BUDGET, ModulePermission.READ, allSites);

	React.useEffect(() => {
		log('ViewWorkOrderDetail.componentDidMount');
		dispatch(getById(orderId, filter));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			dispatch(getById(orderId, filter));
			if (approveReqMade) {
				approveReqMade = false;
				history.goBack();
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onCancelClicked = () => {
		setShowCancelModal(true);
	};

	const handleRejectOrderOk = () => {
		setShowCancelModal(false);
		if (orderId) {
			dispatch(cancel(orderId, {cancelReason}));
			approveReqMade = true;
		}
	};

	const handleRejectOrderCancel = () => {
		setShowCancelModal(false);
	};

	const onApproveClicked = () => {
		setShowApproveModal(true);
	};

	const handleApproveOrderOk = () => {
		setShowApproveModal(false);
		if (orderId) {
			dispatch(approve(orderId, {approveComment}));
			approveReqMade = true;
		}
	};

	const handleApproveOrderCancel = () => {
		setShowApproveModal(false);
	};

	const onWorkOrderQuantityUpdate = (workOrderQuantity: number) => {
		if (!workOrderQuantity) {
			void message.error('Enter work order quantity');
			return;
		}

		Modal.confirm({
			title: `Do you want to update work order quantity of ${workOrderQuantity}?`,
			content: 'This is a one time update. The work order quantity will not update again.',
			onOk() {
				dispatch(update(orderId, {workOrderQuantity}));
			},
		});
	};

	const onTerminateClicked = () => {
		history.push({
			pathname: `/${uiPaths.workOrderTerminate.replace(
				':orderId',
				String(orderId),
			)}`,
		});
	};

	const workOrder = workOrderByIds[orderId];
	const workOrderSchedule = workOrder?.workOrderSchedule ?? scheduleByIds[workOrder?.workOrderScheduleId ?? 0];
	const status = workOrder?.status ? toTitleCase(workOrder?.status) : '';

	const canWrite = Boolean(writeSites.find(s => s.id === workOrder?.siteId));
	const canApprove = equalNum(workOrder?.nextApprovedBy, authUser?.id);
	const canViewBudget = Boolean(viewBudgetSites.find(s => s.id === workOrder?.siteId));
	const canTerminate = workOrder?.status === WorkOrderStatus.PUBLISHED && approveSites.find(s => s.id === workOrder?.siteId);

	return (
		<DefaultLayout currentPath={uiPaths.workOrderDetail}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row style={{marginBottom: 20}}>
					<Col span={24}>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
						<Typography.Title level={4} style={{textAlign: 'center'}}>{workOrder?.title ?? ''}</Typography.Title>
						<Typography.Title level={5} style={{textAlign: 'center'}}>{workOrder?.site?.name}</Typography.Title>
						<br /><br />
						<ColBlock
							label={'Financial'}
							value={
								<table className={'pure-table pure-table-bordered'}>
									<thead>
										<tr>
											<th>Head</th>
											<th>Amount</th>
											<th>Quantity</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Total issued till now</td>
											<td>{getCurrencyString(workOrderSchedule?.totalAmount, false) ?? ''}</td>
											<td>{`${workOrderSchedule?.totalQuantity ?? ''} ${workOrderSchedule?.budgetUnit ?? ''}`}</td>
										</tr>
										<tr>
											<td>This Work Order</td>
											<td>{workOrder?.workOrderAmount}</td>
											<td>{`${workOrder?.workOrderQuantity ?? ''} ${workOrderSchedule?.budgetUnit ?? ''}`}</td>
										</tr>
										{canViewBudget ? (
											<tr>
												<td>Budget for Work Type</td>
												<td>{getCurrencyString(workOrderSchedule?.budgetAmount, false) ?? ''}</td>
												<td>{`${workOrderSchedule?.budgetQuantity ?? ''} ${workOrderSchedule?.budgetUnit ?? ''}`}</td>
											</tr>
										) : []}
									</tbody>
								</table>

							}
						/>
						<ColBlock label={'Work Type'} value={workOrder?.workOrderSchedule?.projectProcessMaster?.processName ?? ''} />
						<ColBlock label={'Contractor Visit'} value={workOrder?.contractorVisit ?? ''} />
						<ColBlock label={'Work Order Status'} value={status} />
						{canWrite ? (
							<ColBlock
								label={'Update Work Order Quantity'}
								value={(
									<EditInputNumberForm
										canEdit={true}
										currentValue={workOrder?.workOrderQuantity}
										onSubmit={value => {
											onWorkOrderQuantityUpdate(value);
										}}
									/>
								)}
							/>
						) : []}
						{workOrder?.orderType === OrderType.RATE && (
							<ColBlock label={'Rate Per Unit'} value={workOrder?.ratePerUnit ?? ''} />
						)}
						{workOrder?.orderType === OrderType.RATE && (
							<ColBlock label={'Quantity Measure Rule'} value={workOrder?.quantityMeasureRule ?? ''} />
						)}
						{workOrder?.billedToClient ? (
							<ColBlock label={'Billed to Client'} value={'Yes'} />
						) : []}
						<ColBlock
							label={'Contractor'}
							value={workOrder?.contractor ? (
								<Link to={{pathname: `/${uiPaths.userDetail.replace(':userId', workOrder?.contractor?.id.toString())}`}}>
									{workOrder?.contractor?.name ?? ''}
								</Link>
							) : []}
						/>
						<ColBlock
							label={'Foremen/Supervisors'}
							value={(
								<Space direction={'vertical'}>
									{workOrder?.contractorForemen?.map((foreman: ContractorForeman) => (
										<Space key={foreman.id}>{foreman.name} {foreman.phone}</Space>
									))}
								</Space>
							)}
						/>
						<ColBlock
							label={'Debits'}
							value={workOrder?.debits ? (
								<Space direction={'vertical'}>
									{workOrder?.debits.map((debit: WorkOrderDebit) => (
										<Typography.Text key={debit.id} type={debit.workOrderBillId ? 'success' : undefined}>
											{debit.workOrderBillId ? '(Recovered in Bill) ' : ''}
											{`${debit.description}: ${getCurrencyString(debit.amount, false)}`}
											{' '}
											{`(${formatDateFunction(debit.addedAt, false)})`}
										</Typography.Text>
									))}
									<b>
										Total Debited Amount:
										{' '}
										{getCurrencyString(workOrder?.debits
											.reduce((amt, debit) => {
												amt += debit.amount;
												return amt;
											}, 0), false)}
									</b>
								</Space>
							) : []}
						/>
						<ColFileBlock
							label={'Scope of Work'}
							textValue={workOrder?.scopeOfWork}
							attachments={workOrder?.scopeOfWorkAttachments}
						/>
						<ColFileBlock
							label={'Material Scope of contractor & Supplied by the Contractor'}
							textValue={workOrder?.materialScopeContractorSupContractor}
							attachments={workOrder?.materialScopeContractorSupContractorAttachments}
						/>
						<ColFileBlock
							label={'Material in Scope of contractor & provided by Prithu'}
							textValue={workOrder?.materialScopeContractorProvPrithu}
							attachments={workOrder?.materialScopeContractorProvPrithuAttachments}
						/>
						<ColFileBlock
							label={'Item rate list for extra work and rework'}
							textValue={workOrder?.itemRateListExtra}
							attachments={workOrder?.itemRateListExtraAttachments}
						/>
						<ColFileBlock
							label={'Quality Defect'}
							textValue={workOrder?.qualityCheck}
							attachments={workOrder?.qualityCheckAttachments}
						/>
						<ColFileBlock
							label={'Project Schedule and Delay Penalty calculation'}
							textValue={workOrder?.schedule}
							attachments={workOrder?.scheduleAttachments}
						/>
						<ColFileBlock
							label={'Contact Person'}
							textValue={workOrder?.contactPerson}
							attachments={workOrder?.contactPersonAttachments}
						/>
						<ColFileBlock
							label={'Term of agreement'}
							textValue={workOrder?.generalClauses}
							attachments={workOrder?.generalClausesAttachments}
						/>
						<ColFileBlock
							label={'Drawing files'}
							textValue={workOrder?.drawingQuantityCalc}
							attachments={workOrder?.drawingQuantityCalcAttachments}
						/>
						<ColFileBlock
							label={'Quantity calculation'}
							textValue={workOrder?.qualityAndPrice}
							attachments={workOrder?.qualityAndPriceAttachments}
						/>

						{
							workOrder?.terminationClausesFiles ? (
								<ColFileBlock
									label={'Termination Clauses'}
									textValue={workOrder?.terminationClauses}
									fileValue={workOrder?.terminationClausesFiles}
								/>
							) : []
						}

						<ColFileBlock
							label={'Tender Comparison Sheet'}
							textValue={workOrder?.tenderComparison}
							attachments={workOrder?.tenderComparisonAttachments}
						/>

						{
							workOrder?.qualityDefect ? (
								<ColFileBlock
									label={'Quality Defect'}
									textValue={workOrder?.qualityDefect}
								/>
							) : []
						}

						{
							workOrder?.paymentScheduleDelayPenalty ? (
								<ColFileBlock
									label={'Payment Schedule Delay Penalty'}
									textValue={workOrder?.paymentScheduleDelayPenalty}
								/>
							) : []
						}

						{
							workOrder?.termOfAgreement ? (
								<ColFileBlock
									label={'Term Of Agreement'}
									textValue={workOrder?.termOfAgreement}
								/>
							) : []
						}

						{
							workOrder?.drawingFiles ? (
								<ColFileBlock
									label={'Drawing Files'}
									textValue={workOrder?.drawingFiles}
								/>
							) : []
						}

						{
							workOrder?.quantityCalc ? (
								<ColFileBlock
									label={'Quantity Calculation'}
									textValue={workOrder?.quantityCalc}
								/>
							) : []
						}

						<ColFileBlock
							label={'Other Files'}
							attachments={workOrder?.otherFilesAttachments}
						/>

						{workOrder?.terminationSummaryAttachments?.length ? (
							<ColFileBlock
								label={'Termination Summary Files'}
								attachments={workOrder?.terminationSummaryAttachments}
							/>
						) : []}

						{workOrder?.approveComments ? (
							<Space direction='vertical' style={{width: '100%'}}>
								<b>Approvals Comments:</b>
								<CommentBlock
									parentId={workOrder.id}
									parentType={CommentParentType.WORK_ORDER_APPROVE}
									authUser={authUser}
									comments={workOrder.approveComments ?? []}
									canComment={false}
									hideUser={true}
								/>
								<br/>
							</Space>
						) : []}

						{workOrder ? <ViewWorkOrderWorkOrderBill workOrder={workOrder} canWrite={canWrite} /> : []}
					</Col>
				</Row>

				<Row>
					<Col span={24} className='mt-15' style={{textAlign: 'right'}}>
						<Space>
							{workOrder?.status !== WorkOrderStatus.PUBLISHED && canApprove ? (
								<Button type='primary' onClick={onApproveClicked}>
									Approve
								</Button>
							) : []}
							{(!workOrder?.status || (workOrder?.status !== WorkOrderStatus.PUBLISHED
								&& workOrder?.status !== WorkOrderStatus.CANCELLED && workOrder?.status !== WorkOrderStatus.TERMINATED)) && canApprove ? (
									<Button type='primary' danger={true} onClick={onCancelClicked}>
									Cancel
									</Button>
								) : []}
							{canTerminate ? (
								<Button type='primary' onClick={onTerminateClicked}>
									Terminate
								</Button>
							) : []}
						</Space>
						{workOrder?.cancelReason
							? <Space><b>Cancellation Reason: </b> <span>{workOrder?.cancelReason}</span></Space> : []}
					</Col>
				</Row>

				{workOrder?.siteId ? (
					<ViewChildQuery
						parentType={QueryParentType.WORK_ORDER}
						parentId={String(orderId)}
						siteId={workOrder.siteId}
						module={ModuleName.WORK_ORDER}
					/>
				) : []}

				<Modal
					title='Cancel Work Order'
					open={showCancelModal}
					onOk={handleRejectOrderOk}
					onCancel={handleRejectOrderCancel}
				>
					<Space direction={'vertical'}>
						<label>Please prove a reason to cancel this work order</label>
						<Input.TextArea
							placeholder={'Enter Cancel Reason'}
							defaultValue={cancelReason ?? ''}
							value={cancelReason ?? ''}
							onChange={(e: any) => {
								setCancelReason(String(e.target.value));
							}}
						/>
					</Space>
				</Modal>

				<Modal
					title='Approve Work Order'
					open={showApproveModal}
					onOk={handleApproveOrderOk}
					onCancel={handleApproveOrderCancel}
				>
					<Space direction={'vertical'}>
						<label>Please enter a comment (optional)</label>
						<Input.TextArea
							placeholder={'Enter a comment'}
							defaultValue={approveComment ?? ''}
							value={approveComment ?? ''}
							onChange={(e: any) => {
								setApproveComment(String(e.target.value));
							}}
						/>
					</Space>
				</Modal>
			</Spin>
		</DefaultLayout>
	);
};
