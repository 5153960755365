import React from 'react';
import {
	Button,
	Col,
	message,
	Popconfirm,
	Row,
	Space,
	Spin,
	Table,
} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {getCurrencyString} from 'app/helpers';
import type Types from 'MyTypes';
import {type PaymentMilestone, type WorkOrder} from 'app/models';
import {getMilestones, approveMilestone, cancelMilestone} from '../WorkOrder/actions';

export const ViewApprovalPaymentMilestone: React.FC = () => {
	const dispatch = useDispatch();
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);
	const {loading, milestones, dataUpdated, errorMessage} = useSelector((state: Types.RootState) => state.workOrder);

	React.useEffect(() => {
		dispatch(getMilestones({where: {nextApprovedBy: authUser?.id ?? 0}}));
	}, []);

	React.useEffect(() => {
		dispatch(getMilestones({where: {nextApprovedBy: authUser?.id ?? 0}}));
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	return (
		<DefaultLayout currentPath={uiPaths.paymentMilestoneApproval}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24}>
						<Table
							size={'small'}
							bordered={true}
							dataSource={milestones}
							pagination={false}
						>
							<Table.Column
								title='Work Order Name'
								dataIndex='workOrder'
								key='workOrder'
								render={(workOrder: WorkOrder) =>
									workOrder?.title ?? ''
								}
							/>
							<Table.Column
								title='Milestone Name'
								dataIndex='name'
								key='name'
							/>
							<Table.Column
								title='Amount'
								dataIndex='amount'
								key='amount'
								render={(amount: number) => getCurrencyString(amount, false)}
							/>
							<Table.Column
								title='Pending for Delete'
								dataIndex='isRemovalPending'
								key='isRemovalPending'
								render={(isRemovalPending: boolean) => isRemovalPending ? 'Yes' : 'No'}
							/>
							<Table.Column
								title='Action'
								dataIndex='action'
								key='action'
								render={(action: string | undefined, milestone: PaymentMilestone) => (
									<Space>
										<Popconfirm
											title={'Are you sure you want to approve!'}
											onConfirm={() => {
												dispatch(approveMilestone(milestone.id));
											}}
										>
											<Button type={'primary'}>Approve</Button>
										</Popconfirm>

										<Popconfirm
											title={'Are you sure you want to cancel!'}
											onConfirm={() => {
												dispatch(cancelMilestone(milestone.id));
											}}
										>
											<Button type={'primary'} danger={true}>Cancel</Button>
										</Popconfirm>
									</Space>
								)}
							/>
						</Table>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
