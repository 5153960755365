import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {
	get,
} from '../WorkOrderBill/actions';
import {Button, Col, Popconfirm, Row, Space, Spin} from 'antd';
import {
	formatDateFunction,
	getCurrencyString,
	getS3Url,
	toTitleCase,
} from 'app/helpers';
import {
	WorkOrderBillStatus,
	type PaymentMilestone,
	type WorkOrder,
	WorkOrderStatus,
} from '../../models';
import {PaperClipOutlined} from '@ant-design/icons';
import {type Penalty} from 'app/models';
import {removeMilestone} from './actions';

type Props = {
	workOrder: WorkOrder;
	canWrite?: boolean;
};

export const ViewWorkOrderWorkOrderBill: React.FC<Props> = ({workOrder, canWrite}) => {
	const dispatch = useDispatch();
	const {loading, byMilestoneIds} = useSelector((state: Types.RootState) => state.workOrderBill);

	React.useEffect(() => {
		if (workOrder) {
			dispatch(get({where: {workOrderId: workOrder.id}}));
		}
	}, []);

	console.log('ByMilestone', byMilestoneIds);
	const getAttachmentView = (attachments: string) => attachments && attachments.split(',').length > 0
		&& attachments.split(',').map((fileKey: string, ixx: number) => (
			<p key={ixx}>
				<a href={getS3Url(fileKey)} target='_blank' rel='noreferrer'>
					{/* {fileKey.split('/')[fileKey.split('/').length - 1]} */}
					<PaperClipOutlined />
				</a>
			</p>
		));

	const getPenaltyView = (penalties: Penalty[]) => penalties?.map(penalty => (
		<p key={penalty.id}>
			{`${penalty.description}: ${getCurrencyString(penalty.amount, (penalty.amount < 0))} by ${toTitleCase(penalty.createdUser?.roles, '_')}`}
		</p>
	));

	return (
		<Spin
			size='large'
			spinning={loading}
			tip={'Loading...'}
		>
			<Row>
				<Col span={24}>
					<table className={'pure-table pure-table-bordered'}>
						<thead>
							<tr>
								<th>S.No.</th>
								<th>Milestone Name</th>
								<th>Milestone Amount</th>
								<th>Bill Amount</th>
								<th>Attachments</th>
								<th>Quantity Measured on Site</th>
								<th>Penalties/Recoveries</th>
								<th>Uploaded At</th>
								<th>Status</th>
								{canWrite ? <th>Action</th> : []}
							</tr>
						</thead>
						<tbody>
							{
								// eslint-disable-next-line complexity
								workOrder.paymentMilestones?.sort((m1: PaymentMilestone, m2: PaymentMilestone) => (m1.sequence ?? 0) >= (m2.sequence ?? 0) ? 0 : -1).map((milestone: PaymentMilestone, ix: number) => {
									const bill = byMilestoneIds[milestone.id];
									console.log('bill', bill);
									let bgColor;
									if (workOrder.status === WorkOrderStatus.TERMINATED) {
										if (bill && bill?.orderBillStatus === WorkOrderBillStatus.PAID) {
											bgColor = 'lightgreen';
										} else if (bill && bill?.orderBillStatus === WorkOrderBillStatus.APPROVED) {
											bgColor = 'yellowgreen';
										} else if (!bill && milestone.sequence !== 0) {
											bgColor = 'red';
										}
									} else if (bill && bill?.orderBillStatus === WorkOrderBillStatus.PAID) {
										bgColor = 'lightgreen';
									} else if (bill && bill?.orderBillStatus === WorkOrderBillStatus.APPROVED) {
										bgColor = 'yellowgreen';
									} else if (milestone.isRemovalPending) {
										bgColor = 'orange';
									}

									console.log(bgColor);
									return (
										// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
										<tr key={ix} style={{background: bgColor}}>
											<td>{milestone.sequence}</td>
											<td>{milestone.name}</td>
											<td>
												{milestone.amount > 0 ? getCurrencyString(milestone.amount, false) : getCurrencyString(milestone.amount)}
											</td>
											<td>
												{bill && getCurrencyString(bill?.billAmount, false)}
											</td>
											<td>
												{getAttachmentView(bill?.attachments)}
											</td>
											<td>
												{bill?.quantityMeasuredOnSite}
											</td>
											<td>
												{bill && getPenaltyView(bill.penalties)}
											</td>
											<td style={{width: 100}}>
												{bill && formatDateFunction(bill.createdAt ?? '')}
											</td>
											<td style={{maxWidth: 120}}>
												<Space direction='vertical'>
													{workOrder.status === WorkOrderStatus.TERMINATED
														? (!bill && milestone.sequence !== 0 ? 'Cancelled' : toTitleCase(bill?.orderBillStatus, '_'))
														: (bill && toTitleCase(bill?.orderBillStatus, '_'))}
													{milestone.isRemovalPending ? 'Pending for Delete' : ''}
												</Space>
											</td>
											{canWrite ? (
												<td>
													{milestone.id && !milestone.workOrderBillId && !milestone.isRemovalPending ? (
														<Popconfirm
															title={'Are you sure you want to remove this milestone!'}
															onConfirm={() => {
																dispatch(removeMilestone(milestone.id));
															}}
														>
															<Button type={'primary'}>Remove</Button>
														</Popconfirm>
													) : []}
												</td>
											) : []}
										</tr>
									);
								})}
							<tr>
								<td>&nbsp;</td>
								<td>Total</td>
								<td>{workOrder.status === WorkOrderStatus.TERMINATED
									? workOrder.paymentMilestones ? getCurrencyString(workOrder.paymentMilestones
										.filter((milestone: PaymentMilestone) => milestone.workOrderBillId ?? milestone.isSettlement)
										.reduce((sum: number, milestone: PaymentMilestone) => {
											sum += milestone.amount;
											return sum;
										}, 0), false) : 0
									: workOrder.paymentMilestones ? getCurrencyString(workOrder.paymentMilestones
										.reduce((sum: number, milestone: PaymentMilestone) => {
											sum += milestone.amount;
											return sum;
										}, 0), false) : 0
								}</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
							</tr>
						</tbody>
					</table>
				</Col>
			</Row>
		</Spin>
	);
};
